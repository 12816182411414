/* Font Variables */

@font-path: 'assets/fonts';
@font-family-icon: 'yjicon';
@font-family-primary: 'Roboto Regular';
@font-family-secondary: 'Open Sans';
@font-family-theme: 'Titillium';

/* Switch font family */

@font-primary: @font-family-primary;
@yj-base-font-family: '@{font-primary}', sans-serif;

@yj-transform: uppercase;
@yj-underline: underline;

@yjicon-assign: '\e900';
@yjicon-email: '\e901';
@yjicon-filarea: '\e902';
@yjicon-license: '\e903';
@yjicon-onboarding: '\e904';
@yjicon-organisation: '\e905';
@yjicon-properties: '\e906';

@yjff-thin: 'Thin';
@yjff-light: 'Light';
@yjff-regular: 'Regular';
@yjff-semibold: 'SemiBold';
@yjff-bold: 'Bold';
@yjff-black: 'Black';
@yjff-condensed: 'Condensed';

/* Extended class */

.yj-font-style {
  font-style: normal;
}

/* Font Sizes */

@font-size-base: .875em;
@font-size-xlg: 1.3em;
@font-size-lg: @font-size-base + 1.2em;
@font-size-sm: @font-size-base/ 2.5;
@font-size-md: .75em;

/* Color Variables */

/* Form Label , Buttons , View button ,Tooltip */

@color-font: #333333;
@color-font-form-label: #0e678e;
@color-bg-primary-button: #24303B;
@color-font-primary-button: #FFFFFF;
@color-bg-default-button: #FFFFFF;
@color-font-default-button: #333333;
@color-border-default-button: #CCCCCC;
@color-bg-view-btn: #24303B;
@color-border-view-btn: #24303B;
@color-font-view-btn: #ffffff;
@color-bg-tooltip-wrapper: #1B69B9;
@color-font-tooltip-wrapper: #ffffff;
@color-bg-add-address-btn: #78bf59;
@color-border-add-address-btn: #78bf59;
@color-font-add-address-btn: #78bf59;
@color-button-primary: #24303B;

/* Error Warning Success Messages */

@color-bg-error-message-wrapper: #f5cace;
@color-bg-error-message-wrapper: #f5cace;
@color-bg-warning-message-wrapper: #ffeeba;
@color-border-warning-message-wrapper: #ffeeba;
@color-font-warning-message-wrapper: #856404;
@color-bg-success-message-wrapper: #c3e6cb;
@color-border-success-message-wrapper: #c3e6cb;
@color-font-success-message-wrapper: #155724;
@color-bg-info-message-wrapper: #CBD8EB;
@color-border-info-message-wrapper: #24303B;
@color-font-info-message-wrapper: #24303B;

/* Side Menu */

@color-mainmenu-icon: #ffffff;
@color-bg-mainmenu-primary: #24303B;

/* Master Layout */

@color-bg-masterlayout: #f0f2f5;
@color-bg-masterlayout-content-wrapper: #f0f2f5;

/* Header Component */

@color-bg-header: #24303B;
@color-bg-header-menu: #24303B;
@color-font-header-menu: #FFFFFF;
@color-font-menu-icon: #FFFFFF;
@color-font-header-title: #FFFFFF;

/* Breadcrumb Component */

@color-bg-breadcrumb: #ffffff;
@color-font-breadcrumb: #333333;

/* Title Component */

@color-bg-titlewrapper: #ffffff;
@color-page-title: #24303B;

/* Main content wrapper */

@color-bg-main-wrapper: #ffffff;

/* Ant Table - Main Grid */

@color-bg-table-header: #d8ecf2;
@color-bg-table-header-sorter-icon: #9bb7c0;
@color-bg-table-header-input-border: #99ccdc;
@color-bg-table-header-hover: #d7ecf2;
@color-bg-table-row-dark: #f6fdff;
@color-bg-table-row-light: #ffffff;
@color-table-header-top-bg: #b8dce8;
@color-table-header-bottom-bg: #d8ecf2;
@color-table-row-highlight: #f0f5f9;
@color-table-row-hover: #e2ecf3;

/* File area action panel */

@color-bg-filearea-actionpanel: #0e678e;
@color-shadow-filearea-actionpanel: #094560;
@color-font-filearea-action-list: #ffffff;
@color-filearea-action-list-icon: #ffffff;
@color-border-filter-dropdown-btn: #ffffff;

/* Wizard Stepper */

@color-bg-wizard-wrapper: #ffffff;
@color-font-verification-icon: #ffffff;
@color-bg-wizard-icon-active: #78bf59;
@color-border-wizard-icon-active: #78bf59;
@color-bg-wizard-icon-previous: #78bf59;
@color-border-wizard-icon-previous: #78bf59;
@color-bg-wizard-icon-wait: #ffffff;
@color-border-wizard-icon-wait: #cccccc;
@color-bg-wizard-container: #ffffff;
@color-bg-wizard-button-section: #ffffff;

/* Collapse - Accordian (Common / Primary) */

@color-bg-accordian-header: #419cb9;
@color-font-accordian-header: #ffffff;
@color-bg-accordian-header-arrow: #ffffff;

/* Collapse - Sub  Accordian (Secondary) */

@color-bg-sub-accordian-wrapper: #ffffff;
@color-bg-sub-accordian-header: #ffffff;
@color-font-sub-accordian-header: #0e678e;
@color-font-sub-accordian-header-icon: #0e678e;

/* Tab Navigation (Common / Primary) */

@color-bg-common-tab-inactive: #ffffff;
@color-font-common-tab-inactive: #333333;
@color-border-common-tab-inactive: #f0f0f0;
@color-bg-common-tab-active: #ffffff;
@color-font-common-tab-active: #0e678e;

/* Tab Navigation (Secondary) */

@color-bg-secondary-tab-active: #78bf59;
@color-font-secondary-tab-active: #ffffff;
@color-border-secondary-tab-inactive: #78bf59;
@color-bg-secondary-tab-inactive: #f0f2f5;
@color-font-secondary-tab-inactive: #333333;

/* Common Grid */

@color-bg-grid-filterby-tag: #78bf59;
@color-border-grid-filterby-tag: #78bf59;
@color-font-grid-filterby-tag: #ffffff;
@color-font-grid-filterby-tag-icon: #ffffff;
@color-font-grid-recordcount: #333333;

/* History Grid */

@color-bg-history-grid-header: #ffffff;
@color-font-history-grid-header: #007BA0;
@color-font-history-grid-record: #333333;

/* Secondary Grid */

@color-bg-secondary-header: #ffffff;
@color-font-secondary-header: #007BA0;
@color-bg-remove-record-icon: #e3001c;
@color-font-remove-record-icon: #ffffff;

/* Common Modal */

@color-bg-modal-header: #24303B;
@color-font-modal-header: #ffffff;
@color-bg-modal: #ffffff;
@color-close-icon-modal: #ffffff;

/* Pagination */

@color-bg-pagination-active: #24303B;
@color-border-pagination-active: #24303B;
@color-font-pagination-active: #ffffff;

/* File finder section - file area */

@color-bg-file-finder-header: #007BA0;
@color-font-file-finder-header: #ffffff;
@color-bg-save-new-filter-btn: #78bf59;
@color-border-save-new-filter-btn: #78bf59;
@color-font-save-new-filter-btn: #ffffff;
@color-filter-area-shadow: #4d7e8d;
@color-bg-filter-area: #ffffff;
@color-bg-progress-bar: #78bf59;

/* Portal file area */
@color-bg-portal-action-button-wrapper: #007BA0;
@color-bg-portal-action-button: #ffffff;
@color-font-portal-action-button: #007BA0;

/* FF Management - edit , License Management - edit */

@color-bg-functional-flow-management-wrapper-edit: #ffffff;
@color-bg-license-management-wrapper-edit: #ffffff;
@color-bg-organization-management-wrapper-edit: #ffffff;

/* Content Wrapper */

@color-bg-content-wrapper: #ffffff;

/* Drag and Drop Container */

@color-bg-drag-drop-container: #007BA0;
@color-border-drag-drop-container: #007BA0;
@color-font-drag-drop-container: #333333;
@color-bg-browse-btn: #24303B;
@color-font-browse-btn: #ffffff;

/* Upload Progress bar */

@color-bg-upload-progress-wrapper: #EFEFEF;

/* Ant Drawer */

@color-bg-drawer-header: #24303B;
@color-font-drawer-header-title: #ffffff;
@color-font-drawer-header-icon: #ffffff;
@color-bg-drawer-body: #ffffff;

/* External Window */

@color-bg-external-wrapper: #f0f2f5;
@color-bg-external-wrapper-header: #f0f2f5;
@color-bg-external-title-section: #ffffff;
@color-font-external-title-section: #24303B;
@color-bg-external-content: #ffffff;

/* Checkbox styles */

@color-bg-checkbox-default: #fff;
@color-border-checkbox-default: #24303B;
@color-font-checkbox-default: #24303B;

/* Global Variables */

@color-primary: #24303B;
@color-secondary: #134A82;
@color-accent-secondary: #1B69B9;
@color-accent: #00bcd4;
@color-bg: #f0f2f5;
@color-bg-secondary: #e4e7e9;
@color-bg-secondary: #e4e7e9;
@color-tag: #E2ECF3;
@color-bg-white: #ffffff;
@color-font-white: #ffffff;
@color-modal-bg-sub-header: linear-gradient(180deg, #d7ecf2 0%, #d8ecf2 100%);
@color-border: #e7e9ec;
@color-accent-border: #505962;
@color-white: #ffffff;
@color-danger: #e3001c;
@color-success: #207e31;
@color-warning: #b35900;
@border-color-base: #f6f6f6;
@text-color: fade(black, 65%);
@yj-placeholder-txt: #bfbfbf;

@color-info-alert: #004085;
@color-info-alert-border: #b8daff;
@color-info-alert-bg: #b8daff;

@color-success-alert: #155724;
@color-success-alert-border: #c3e6cb;
@color-success-alert-bg: #d4edda;

@color-warning-alert: #856404;
@color-warning-alert-border: #ffeeba;
@color-warning-alert-bg: #fff3cd;

@color-error-alert: #721c24;
@color-error-alert-border: #f5c6cb;
@color-error-alert-bg: #f8d7da;

@color-disabled-bg: #bcbcbc;
@color-disabled-border: #aeaeae;

/* Media quaries breakpoint */

@breakpoint-tablet: ~'only screen and (max-width: 1366px)';
