@import '_variables';
@import '_mixins';

@font-face {
  &:extend(.yj-font-style);

  font-family: '@{font-family-icon}';
  src: url('@{font-path}/@{font-family-icon}.eot?16a1dd');
  src: url('@{font-path}/@{font-family-icon}.eot?16a1dd#iefix') format('embedded-opentype'),
    url('@{font-path}/@{font-family-icon}.ttf?16a1dd') format('truetype'),
    url('@{font-path}/@{font-family-icon}.woff?16a1dd') format('woff'),
    url('@{font-path}/@{font-family-icon}.svg?16a1dd#@{font-family-icon}') format('svg');
}

[class^='yjicon-'],
[class*=' yjicon-'] {

  /* use !important to prevent issues with browser extensions that change fonts */

  &:extend(.yj-font-style);

  /* Better Font Rendering =========== */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

.yjicon-assign {

  &::before {
    content: @yjicon-assign;
  }
}

.yjicon-email {

  &::before {
    content: @yjicon-email;
  }
}

.yjicon-filarea {

  &::before {
    content: @yjicon-filarea;
  }
}

.yjicon-license {

  &::before {
    content: @yjicon-license;
  }
}

.yjicon-onboarding {

  &::before {
    content: @yjicon-onboarding;
  }
}

.yjicon-organisation {

  &::before {
    content: @yjicon-organisation;
  }
}

.yjicon-properties {

  &::before {
    content: @yjicon-properties;
  }
}

//Roboto

@font-face {
  &:extend(.yj-font-style);

  src: url('@{font-path}/Roboto-200.ttf');
  src: url('@{font-path}/Roboto-200.eot?#iefix') format('embedded-opentype'),
    url('@{font-path}/Roboto-200.woff') format('woff'),
    url('@{font-path}/Roboto-200.ttf')  format('truetype');

  .font-mixin(@font-family-primary, @yjff-light);
}

@font-face {
  &:extend(.yj-font-style);

  src: url('@{font-path}/Roboto-300.eot');
  src: url('@{font-path}/Roboto-300.eot?#iefix') format('embedded-opentype'),
    url('@{font-path}/Roboto-300.woff') format('woff'),
    url('@{font-path}/Roboto-300.ttf')  format('truetype');

  .font-mixin(@font-family-primary, @yjff-regular);
}

@font-face {
  &:extend(.yj-font-style);

  src: url('@{font-path}/Roboto-500.eot');
  src: url('@{font-path}/Roboto-500.eot?#iefix') format('embedded-opentype'),
    url('@{font-path}/Roboto-500.woff') format('woff'),
    url('@{font-path}/Roboto-500.ttf')  format('truetype');

  .font-mixin(@font-family-primary, @yjff-condensed);
}

@font-face {
  &:extend(.yj-font-style);

  src: url('@{font-path}/Roboto-600.eot');
  src: url('@{font-path}/Roboto-600.eot?#iefix') format('embedded-opentype'),
    url('@{font-path}/Roboto-600.woff') format('woff'),
    url('@{font-path}/Roboto-600.ttf')  format('truetype');

  .font-mixin(@font-family-primary, @yjff-semibold);
}

@font-face {
  &:extend(.yj-font-style);

  src: url('@{font-path}/Roboto-700.eot');
  src: url('@{font-path}/Roboto-700.eot?#iefix') format('embedded-opentype'),
    url('@{font-path}/Roboto-700.woff') format('woff'),
    url('@{font-path}/Roboto-700.ttf')  format('truetype');

  .font-mixin(@font-family-primary, @yjff-bold);
}
