@import '@{file-path}/assets/images/_imagepath';
@import '@{file-path}/_mixins';
@import '@{file-path}/_variables';
@file-path: '../../../styles/';

.yj_tenant_selection_container {
    width: 100%;
    height: calc(100vh - 65px);
}

.yj_tenant_selection {
    text-align: center;
    padding: 10px;
    background-color: white;
    width: 400px;
    margin: 30% 0;

    hr {
        height: 1px;
        border: none;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
    }
}

.yj_cp_app_logo {
    background: url(@app-logo) no-repeat center center;
    cursor: pointer;
    height: 100%;
    width: 150px;
}

.yj_tenant_userLogo {
    border: 1px solid rgba(0, 0, 0, 0.2);
    ;
    border-radius: 50%;
    width: 50px;
    padding: 2px;
}

.yj_tenant_title {
    text-align: left;
    padding: 10px;
}

.yj_tenant_dropdown {
    width: 100%;
    padding-bottom: 10px;
}
@primary-color: #419cb9;@link-color: #0e678e;@font-size-base: 14px;