@import '_variables';
@import '_mixins';
@import '_fonts';
@import '_yjcommon';
@import '../styles/assets/images/_imagepath';

body {
  font-family: @yj-base-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
}

html,
body {
  height: 100%;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

@primary-color: #419cb9;@link-color: #0e678e;@font-size-base: 14px;