@import '~antd/es/style/themes/default.less';
@import '@{file-path}/assets/images/_imagepath';
@import '@{file-path}/_mixins';
@import '@{file-path}/_variables';
@file-path: '../../../styles/';

.NotFoundPage {
  padding-top: 10%;
  text-align: center;
}

.NotFoundPage h1 {
  padding-top: 1%;
}

.NotFoundPage button {
  all: unset;
  color: @color-secondary;
  cursor: pointer;
}

@primary-color: #419cb9;@link-color: #0e678e;@font-size-base: 14px;