@import '@{file-path}/assets/images/_imagepath';
@import '@{file-path}/_mixins';
@import '@{file-path}/_variables';
@file-path: '../../../styles/';

.yj_cp_header {
  background-color: @color-bg-header;
  border-bottom: 1px solid #1B69B9;
  color: @color-font-header-menu;
  padding: 0 10px;

  .yj_cp_firm_logo {
    background: url(@app-logo) no-repeat center center;
    cursor: pointer;
    height: 100%;
    width: 150px;
  }
  .yj_cp_firm_name {
    display: none;
    color: @color-font-header-title;
    line-height: 100%;
    font-size: 26px;
    width: 100%;
    margin: auto 30px;
  }

  .cp_header_username{
    display: inline;
    font-size: 10px;
    margin-right: 10px;
  }

  .yj_cp_header_menu {

    li {
      color: @color-font-header-menu;

      svg {
        color: @color-font-menu-icon;
      }
    }
  }

  .flex-mixin(center, flex, space-between);
}

@primary-color: #419cb9;@link-color: #0e678e;@font-size-base: 14px;