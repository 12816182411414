@import '@{file-path}/assets/images/_imagepath';
@import '@{file-path}/_mixins';
@import '@{file-path}/_variables';

@file-path: '../../../styles/';

.yj_cp_breadcrumb_wrapper {
  background-color: @color-bg-breadcrumb;
  border-top: 1px solid @border-color-base;

  .yj_cp_breadcrumb {
    border-bottom: 1px solid #B1AFAF;
    box-shadow: 2px 0 6px rgba(213, 214, 216, 0.55);
    color: @color-font-breadcrumb;
    font-size: .95em;
    padding: .5em 1.5em;

    span {

      &:last-child {
        color: #007BA0;
        .font-mixin(@font-primary, @yjff-regular);
      }
    }
  }

  .font-mixin(@font-primary, @yjff-regular);
}
@primary-color: #419cb9;@link-color: #0e678e;@font-size-base: 14px;