@import '~antd/es/style/themes/default.less';
@import '@{file-path}/assets/images/_imagepath';
@import '@{file-path}/_mixins';
@import '@{file-path}/_variables';

@file-path: '../../../styles/';

.yj_cp_expand_menu {
  color: @color-mainmenu-icon;
  font-size: 1.7em;
  line-height: 2em;
  padding: 0 1em;
  transition: color .3s;
}


.yj_cp_sidemenu {
  background: @color-bg-mainmenu-primary;
  border: none;
  padding-left: 50px;

  li {
    color: white;
    font-size: 16px;
    font-weight: 500;
  }
}

.yj_cp_sidemenu_wrapper {
  background: @color-bg-mainmenu-primary;
  height: calc(100vh);
  overflow-y: auto;
  padding-bottom: 125px;

  .yj_cp_sidemenu_hamburger_button {
    background-color: #C8102E;
    border: none;
    border-radius: 10px 0 0 10px;
    box-shadow: 2px 0 6px rgba(0, 21, 41, .35);
    height: 50px;
    width: 50px;
    padding: 10px;

    &:active {
      background-color: #C8102E;
      border: none;
    }

    &:hover {
      background-color: #C8102E;
      border: none;
    }

    svg {
      color: white;
      font-size: 26px;
    }
  }
}

.yj_cp_menu_item_count {
  position: absolute;
  top: 0;
  right: 40px;
  background-color: red;
  border: 1px solid red;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  font-size: 20px;
  transform: scale(0.6);
}

.yj_cp_menu_item_count_collapsed {
  position: absolute;
  top: -5px;
  right: 0px;
  background-color: red;
  border: 1px solid red;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  font-size: 22px;
  transform: scale(0.6);
}
@primary-color: #419cb9;@link-color: #0e678e;@font-size-base: 14px;