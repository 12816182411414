@import '@{file-path}/assets/images/_imagepath';
@import '@{file-path}/_mixins';
@import '@{file-path}/_variables';

@file-path: '../../../styles/';

.yj_cp_page_title_wrapper {
  background-color: @color-bg-titlewrapper;
  justify-content: space-between;
  padding: 0.5em 2em;

  .yj_cp_page_title {
    color: @color-page-title;
    flex-basis: 50%;
    margin: .2em 0;
    text-transform: uppercase;
    font-weight: 400;
    color: #56595A;
    font-size: 22px;

    .flex-mixin(center, flex, flex-start);
    p {
      margin-bottom: 0;
      padding: 10px 0;
    }
  }

  .flex-mixin(center, flex, space-between);
}

@media (max-width: 1366px) {

  .yj_cp_page_title_wrapper {

    .yj_cp_page_title {

      h2 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 570px;
      }
    }
  }
}
@primary-color: #419cb9;@link-color: #0e678e;@font-size-base: 14px;